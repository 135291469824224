.u-accent {
  color: #0000d2;
}

.u-anomaly {
  color: #f91b1b;
}

.appLink {
  cursor: pointer;
}

.btn.MuiButton-root {
  text-transform: capitalize;
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 22px;
  color: #0000d2;
  background: #ebf2ff;
  border-color: #ebf2ff;
  box-shadow: none;
}

.btn.MuiButton-root:hover {
  color: #0000d2;
  background: #e0ebff;
  border-color: #e0ebff;
  box-shadow: none;
}

.btn.MuiButton-root:focus {
  color: #0000d2;
  background: #e0ebff;
  border-color: #e0ebff;
  box-shadow: none;
}

.txt-mainDark-11-500 {
  margin: 0;
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  color: #050c2e;
}

.txt-mainDark-13-500 {
  margin: 0;
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  color: #050c2e;
}

.txt-mainDark-13-600 {
  margin: 0;
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #050c2e;
}

.txt-mainDark-13-700 {
  margin: 0;
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 22px;
  color: #050c2e;
}

.txt-mainDark-16-500 {
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 32px;
  font-weight: 500;
  color: #050c2e;
  margin: 0;
}

.txt-mainDark-16-700 {
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  color: #050c2e;
  margin: 0;
}

.txt-mainDark-20-700 {
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #050c2e;
  margin: 0;
}

.txt-mainDark-24-700 {
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #050c2e;
  margin: 0;
}

.txt-mainDark-32-700 {
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #050c2e;
  margin: 0;
}

.txt-grey-10-500 {
  margin: 0;
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: normal;
  color: #6a718f;
}

.txt-grey-11-500 {
  margin: 0;
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  color: #6a718f;
}

.txt-grey-13-400 {
  margin: 0;
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #6a718f;
}

.txt-grey-13-500 {
  margin: 0;
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  color: #6a718f;
}

.txt-grey-13-700 {
  margin: 0;
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 22px;
  color: #6a718f;
}

.txt-grey-16-500 {
  margin: 0;
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 32px;
  font-weight: 500;
  color: #6a718f;
}

.txt-grey-20-700 {
  margin: 0;
  font-family: Inter, Arial, sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: #6a718f;
}

.txt-grey-24-700 {
  margin: 0;
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: #6a718f;
}

.txt-lightGrey-20-700 {
  margin: 0;
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: #bec5e7;
}

.txt-lightGrey-24-700 {
  margin: 0;
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: #bec5e7;
}

.txt-lightGrey-32-700 {
  margin: 0;
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #bec5e7;
}

.txt-blue-11-500 {
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  color: #0000d2;
  margin: 0;
}

.txt-blue-13-500 {
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  color: #0000d2;
  margin: 0;
}

.txt-blue-13-700 {
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 22px;
  color: #0000d2;
  margin: 0;
}

.txt-blue-24-700 {
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #0000d2;
  margin: 0;
}

.txt-red-13-500 {
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  color: #f91b1b;
  margin: 0;
}

.txt-dark-red-13-500 {
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  color: #b30040;
  margin: 0;
}

.buttons {
  display: block;
  text-align: right;
}
.buttons .btn {
  float: none;
}

.empty-data {
  display: flex;
  align-items: center;
  min-height: 150px;
  height: 100%;
  justify-content: center;
}

.styles-override .btn {
  box-sizing: border-box;
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 22px;
  color: #050c2e;
  border-radius: 4px;
  padding: 11px;
}

.styles-override .btn-light {
  background: #f7f8fc;
  border-color: #f7f8fc;
  box-shadow: none;
}

.styles-override .btn-white {
  background: #ffffff;
  border-color: #ffffff;
  box-shadow: none;
}

.styles-override .btn-white:hover {
  background: #ffffff;
  border-color: #ffffff;
  box-shadow: none;
}

.styles-override .btn-white:focus {
  background: #ffffff;
  border-color: #ffffff;
  box-shadow: none;
}

.styles-override .btn-blue {
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 22px;
  color: #0000d2;
  background: #ebf2ff;
  border-color: #ebf2ff;
  box-shadow: none;
}

.styles-override .btn-blue:hover {
  color: #0000d2;
  background: #e0ebff;
  border-color: #e0ebff;
  box-shadow: none;
}

.styles-override .btn-blue:focus {
  color: #0000d2;
  background: #e0ebff;
  border-color: #e0ebff;
  box-shadow: none;
}

.no-select-text {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.textOverflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.textOneLine {
  white-space: nowrap;
  overflow: hidden;
}

.nowrapText {
  white-space: nowrap;
}

.highlight {
  background-color: #f9a01b;
}

.uppercase {
  text-transform: uppercase;
}

.input-label {
  display: block;
  margin-bottom: 12px;
  font-family: Inter, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  color: #6a718f;
}

.label-asterisk {
  color: #f91b1b;
}

.loaderContainer {
  height: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: 3;
  background-color: #f7f8fc99;
  border-radius: 12px;
}

.transitionEffect {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}
